import React, {useEffect, useRef, useState} from 'react';
import {
    Box,
    Breadcrumbs,
    Button,
    Checkbox,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider, IconButton, ListItem,
    ListItemButton,
    ListItemText,
    SelectChangeEvent,
    TableSortLabel,
    TextField,
    Typography
} from "@mui/material";
import {useTranslation} from "react-i18next";
import {DragDropContext, Draggable, Droppable, DropResult} from "@hello-pangea/dnd";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import './VoohForm.scss'
import {useTheme} from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import TableContainer from "@mui/material/TableContainer";
import Link from "@mui/material/Link";
import {MediabankResource, MediabankResourceTableRow, ResourceType} from "../../mediabank/types";
import FolderOutlinedIcon from "@mui/icons-material/FolderOutlined";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import {Thumbnail} from "../../../config/images";
import {BreadCrumb} from "../../mediabank/store/mediabankResourcesSlice";
import {useGetMediabankResourcesForSelectQuery} from "../../mediabank/api/mediabankResourcesApiSlice";
import {visuallyHidden} from "@mui/utils";
import SubdirectoryArrowRightIcon from '@mui/icons-material/SubdirectoryArrowRight';
import {
    useCreateMediaTemplateMutation, useCreateVoohPlaylistMutation, useDeleteMediaTemplateMutation,
    useGetMediaTemplatesQuery,
    useLazyGetResourcesMetaDataQuery
} from "../api/voohApiSlice";
import {ResourceMetaData, ScheduleRow, VoohCategory, VoohItem, VoohItemType} from "../types";
import {useGetCmEnumsQuery} from "../../../app/appApiSlice";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import {useDispatch, useSelector} from "react-redux";
import {initialVoohFormState, selectVoohForm, setVoohForm} from "../store/voohSlice";
import {
    useCreateDraftMutation, useDeleteDraftMutation,
    useGetDraftsQuery,
    useGetMediaTreeQuery,
    useGetTagsQuery
} from "../../drafts/api/draftsApiSlice";
import {MediaNode} from "../../drafts/types";
import OutlinedInput from "@mui/material/OutlinedInput";
import Chip from "@mui/material/Chip";
import CheckboxTree from "react-checkbox-tree";
import {DateTimePicker} from "@mui/x-date-pickers";
import dayjs from "dayjs";
import {
    ADVERTISEMENTS_COLOR,
    DEFAULT_ADVERTISEMENTS,
    MIN_ADVERTISEMENTS,
    VOOH_SCHEDULE_HEIGHT
} from "../../../config/vooh";
import useDebounce from "../../../hooks/useDebounce";
import {getUuid} from "../../../config/general";
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

import CloseIcon from '@mui/icons-material/Close';
import {enqueueSnackbar} from "notistack";
import ConfirmDialog from "../../shared/confirmDialog/ConfirmDialog";
import OwnershipSelect from "../../shared/ownershipSelect/OwnershipSelect";
import {setCreationForm} from "../../creations/store/creationsSlice";
import {useNavigate} from "react-router-dom";

type Order = 'asc' | 'desc';

interface SelectedMediabankResource {
    propId: number;
    resourceId: number;
    name: string;
    thumbnailUrl: string | null;
    resourceUrl: string | null;
}

interface EnhancedTableProps {
    numSelected: number;
    onRequestSort: (event: React.MouseEvent<unknown>, property: keyof MediabankResourceTableRow) => void;
    onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
    order: Order;
    orderBy: string;
    rowCount: number;
}

interface EnhancedTableToolbarProps {
    numSelected: number;
}

interface HeadCell {
    disablePadding: boolean;
    id: keyof MediabankResourceTableRow;
    label: string;
    numeric: boolean;
    sort: boolean;
}

const VoohForm = () => {

    const mediabankBreadcrumbsInitialState = [{
        dirName: 'Katalog główny',
        resourceId: null
    }]

    const headCells: readonly HeadCell[] = [
        {
            id: 'name',
            numeric: false,
            disablePadding: true,
            label: 'Nazwa folderu',
            sort: true
        },
        {
            id: 'byteSize',
            numeric: false,
            disablePadding: true,
            label: 'Rozmiar',
            sort: true
        },
        {
            id: 'metadata',
            numeric: false,
            disablePadding: true,
            label: 'Rozdzielczość',
            sort: false
        },
        {
            id: 'tagList',
            numeric: true,
            disablePadding: false,
            label: 'Tagi',
            sort: false
        },
        {
            id: 'categoryId',
            numeric: true,
            disablePadding: false,
            label: 'Kategoria',
            sort: false
        },
        {
            id: 'updatedAt',
            numeric: true,
            disablePadding: false,
            label: 'Data wgrania',
            sort: true
        },
    ];


    const {t} = useTranslation();
    const [scheduleRows, setScheduleRows] = useState<ScheduleRow[]>([]);
    const navigate = useNavigate();

    const handleDragEnd = (result: DropResult) => {

        try {
            const {source, destination} = result;
            if (destination?.droppableId === 'droppable-2' || !mediabankResources) {
                return
            }
            if (!result.destination) {
                return;
            }
            if (source.droppableId === destination?.droppableId) {
                let tempData = Array.from(scheduleRows);
                let [source_data] = tempData.splice(result.source.index, 1);
                tempData.splice(result.destination.index, 0, source_data);
                setScheduleRows(tempData);
            } else {
                if (result.draggableId === 'advertisement') {
                    let tempData = Array.from(scheduleRows);
                    tempData.splice(destination?.index || 0, 0, {
                        name: 'REKLAMA',
                        voohItemType: VoohItemType.Advertisement,
                        uuid: getUuid(0),
                        duration: 0,
                        categoryId: null
                    })
                    setScheduleRows(tempData);
                    setRollbackAdvNumber(true)
                    setAdvertisingBlocksNumber(advertisingBlocksNumber + 1)
                    return;
                }

                const draggedItem = visibleRows[result.source.index];

                const newRows = selected.find(el => el === draggedItem.id) ? selected : [draggedItem.id];

                getFilesMeta(newRows).then(async (res: ResourceMetaData[]) => {
                    const newScheduleRows: ScheduleRow[] = res.map((resourceMetaData, index) => {
                        return {
                            ...resourceMetaData,
                            name: mediabankResources?.find(el => el.id === resourceMetaData.mediabankResourceId)?.name || '',
                            uuid: getUuid(index),
                            categoryColor: voohCategories?.filter(el => el.id === resourceMetaData.categoryId)[0]?.color || '',
                            categoryName: voohCategories?.filter(el => el.id === resourceMetaData.categoryId)[0]?.name || '',
                            voohItemType: VoohItemType.MediabankResource
                        }
                    })
                    let tempData = Array.from(scheduleRows);
                    tempData.splice(destination?.index || 0, 0, ...newScheduleRows)
                    setScheduleRows(tempData);
                })

            }
        } catch {

        }


    };
    const dispatch = useDispatch();

    const theme = useTheme();
    const [openMediabankDialog, setOpenMediabankDialog] = React.useState<boolean>(false);
    const [breadcrumbsForMediabankDialog, setBreadcrumbsForMediabankDialog] = React.useState<BreadCrumb[]>(mediabankBreadcrumbsInitialState);
    const [parentIdForForMediabankDialog, setParentIdForMediabankDialog] = React.useState<string | null>(null);
    const [selectedMediabankResources, setSelectedMediabankResources] = React.useState<SelectedMediabankResource[]>([]);
    const [selected, setSelected] = React.useState<number[]>([]);
    const [order, setOrder] = React.useState<Order>('asc');
    const [orderBy, setOrderBy] = React.useState<keyof MediabankResourceTableRow>('name');
    const [checkedNodes, setCheckedNodes] = useState<string[]>([]);
    const [filteredNodes, setFilteredNodes] = useState([]);
    const [templateName, setTemplateName] = useState<string | null>(null);
    const [advertisingBlocksNumber, setAdvertisingBlocksNumber] = useState(DEFAULT_ADVERTISEMENTS);
    const [advertisingBlocksNumberPrevValue, setAdvertisingBlocksNumberPrevValue] = useState(DEFAULT_ADVERTISEMENTS);
    const [nodes, setNodes] = useState([]);
    const [treeState, setTreeState] = useState({key: Math.random()});
    const [openMediaTreeModal, setOpenMediaTreeModal] = useState(false);
    const checkboxTreeRef = useRef<any>()
    const [filterNodesText, setFilterNodesText] = useState('')
    const [expanded, setExpanded] = useState([]);
    const [openWarningAfterChangingAdvNumber, setOpenWarningAfterChangingAdvNumber] = React.useState<boolean>(false);
    const [openSaveTemplateModal, setOpenSaveTemplateModal] = React.useState<boolean>(false);
    const [selectedTemplate, setSelectedTemplate] = useState<number | string>('')
    const [openRemoveTemplateConfirmation, setOpenRemoveTemplateConfirmation] = useState<number | null>(null);
    const [rollbackAdvNumber, setRollbackAdvNumber] = useState<boolean>(false);
    const [filterByIds, setFilterByIds] = useState<string>('');
    const [showFileExplorer, setShowFileExplorer] = useState<boolean>(true);

    const [mediaLength, setMediaLength] = useState<number | null>(null);
    const voohForm = useSelector(selectVoohForm);

    const {isLoading, isError, isSuccess, data: mediaTemplatesData} =
        useGetMediaTemplatesQuery();
    const [createMediaTemplate, {isLoading: creatingMediaTemplate}] = useCreateMediaTemplateMutation()
    const [createVoohPlaylist, {isLoading: creatingVoohPlaylist}] = useCreateVoohPlaylistMutation()

    const [deleteMediaTemplate, {isLoading: deletingMediaTemplate}] = useDeleteMediaTemplateMutation()


    let checkedMediaOnFilterById: string[] = []

    const [formErrors, setFormErrors] = useState({
        name: false,
        startAt: false,
        endAt: false,
        media: false,
    })
    const {isLoading: loadingMediaTree, data: mediaTree} =
        useGetMediaTreeQuery('MOVE_TV' || '');

    const {isLoading: loadingResourcesForMediabankDialog, data: resourcesDataForMediabankDialog, isFetching} =
        useGetMediabankResourcesForSelectQuery(parentIdForForMediabankDialog);


    const [getFilesMetaData, {data: filesMetaData}] = useLazyGetResourcesMetaDataQuery()

    const {isLoading: loadingCmEnums, data: cmEnums} =
        useGetCmEnumsQuery();

    const {isLoading: loadingTags, data: tagsData} =
        useGetTagsQuery();

    const voohCategories: VoohCategory[] | undefined = cmEnums && cmEnums?.filter(el => el.name === 'categories')[0].values as VoohCategory[]

    const mediabankResources = resourcesDataForMediabankDialog?.data;

    useEffect(() => {
        dispatch(setVoohForm({
            ...initialVoohFormState.voohForm
        }));
        const advertisements: ScheduleRow[] = [...Array(advertisingBlocksNumber).keys()].map((_, index) => {
            return {
                name: 'REKLAMA',
                uuid: getUuid(index),
                voohItemType: VoohItemType.Advertisement,
                duration: 0,
                categoryId: null
            }
        })
        setScheduleRows(advertisements)
    }, [])


    useEffect(() => {
        const voohItems: VoohItem[] = scheduleRows.map((element, position) => {
            return {
                mediabankResourceId: element.mediabankResourceId,
                position,
                duration: element.duration,
                categoryId: element.categoryId,
                itemType: element.voohItemType
            }
        })

        dispatch(setVoohForm({
            ...voohForm,
            voohItemsAttributes: voohItems,
        }));

    }, [scheduleRows])

    let mediaSize = 0

    const onSelectMediaById = (event: any) => {
        setFilterByIds(event.target.value)
    }

    const createTemplate = async () => {
        if (!templateName) {
            return;
        }

        try {
            const payload = {
                name: templateName,
                localisablesMediatablesAttributes: checkedNodes.map((el) => {
                    return {
                        localisableId: parseInt(el.split('_')[0]),
                        localisableType: el.split('_')[1]
                    }

                })
            }
            const result = await createMediaTemplate(payload).unwrap()
            setOpenSaveTemplateModal(false);
            setTemplateName(null)
        } catch (err: any) {
            console.log(err)
            if (err?.status && err.status === 422) {
                enqueueSnackbar('Wystąpił błąd - nazwa już istnieje w bazie, proszę wybrać inną', {variant: 'error'})
            } else {
                enqueueSnackbar('Wystąpił błąd', {variant: 'error'});
            }
            setOpenSaveTemplateModal(false);
            setTemplateName(null)
        }
    }

    const removeTemplate = async (templateId: number) => {
        try {
            const result = await deleteMediaTemplate(templateId).unwrap()
            setSelectedTemplate('')
        } catch (err: any) {
            console.log(err)
            enqueueSnackbar('Wystąpił błąd', {variant: 'error'});
        }
    }

    const filterNodesByIds = (filtered: any, node: any) => {
        const filterByIdsArr = filterByIds.split(/,| |;/)
        const nodeId = node.label.split('-')[0].trim()
        if (
            // Node's label matches the search string
            filterByIdsArr.some(filteredNodeId => filteredNodeId === nodeId)
        ) {
            if (node.value.includes('_Medium')) {
                checkedMediaOnFilterById.push(node.value)
            }

            filtered.push(node)
            return filtered;
        }

        if (node.children && node.children.length > 0) {
            const children = (node.children || []).reduce(filterNodesByIds, []);
            if (children.length > 0) {
                filtered.push({...node, children});
            }
        }
        return filtered;
    }


    const onFilterNodeChange = (event: any) => {
        setFilterNodesText(event.target.value)
    }
    const filterNodes = (filtered: any, node: any) => {
        if (
            // Node's label matches the search string
            node.label.toLocaleLowerCase().match(filterNodesText.toString().toLocaleLowerCase())
        ) {
            filtered.push(node)
            return filtered;
        }

        if (node.children && node.children.length > 0) {
            const children = (node.children || []).reduce(filterNodes, []);
            if (children.length > 0) {
                filtered.push({...node, children});
            }
        }
        return filtered;
    }

    const buildMediaTreeCheckboxNodes: any = (mediaTree: MediaNode[]) => {
        return mediaTree.map((element: MediaNode) => {
            if (element.children && element.children.length > 0) {
                return {
                    value: element.id + '_' + element.type,
                    label: element.name + ' ' + `${element.type === 'Media::Agglomeration' ? '(Aglomeracja)' : '(Miasto)'}`,
                    children: buildMediaTreeCheckboxNodes(element.children),
                }
            } else {
                mediaSize += 1;
                return {
                    value: element.id + '_' + element.type,
                    label: element.asId ? element.asId + ' - ' + element.name : element.name,
                }
            }
        })
    }

    useEffect(() => {
        if (mediaTree) {
            mediaSize = 0;
            const mediaTreeCheckboxNodes = buildMediaTreeCheckboxNodes(mediaTree)
            setTimeout(() => {
                setNodes(mediaTreeCheckboxNodes)
                setFilteredNodes([{
                    label: 'Wszystkie nośniki',
                    value: 'all',
                    children: mediaTreeCheckboxNodes
                }] as any)
                setMediaLength(mediaSize)
            }, 200)
        }


    }, [mediaTree]);


    useEffect(() => {
        const checkedMedia = checkedNodes.filter(el => el !== 'all').map((node: string) => {
            return node.split('_')[0]
        })

        const mediaToAdd = checkedMedia.map((checkedMediaElement) => {
            return {localisableId: checkedMediaElement, localisableType: 'Medium'}
        })
        setFormErrors({...formErrors, media: false})
        dispatch(setVoohForm({
            ...voohForm,
            localisablesMediatablesAttributes: [...mediaToAdd as []]
        }));
    }, [checkedNodes])

    useDebounce(() => {
            setFilterByIds('')
            // Reset nodes back to unfiltered state
            if (!filterNodesText) {
                setFilteredNodes([{
                    label: 'Wszystkie nośniki',
                    value: 'all',
                    children: nodes
                }] as any);
                return;
            }
            setFilteredNodes(
                [{
                    label: 'Wszystkie nośniki',
                    value: 'all',
                    children: nodes.reduce(filterNodes, [])
                }] as any)
            checkboxTreeRef.current.onExpandAll()
        }, [filterNodesText], 100
    );


    useDebounce(() => {
            checkedMediaOnFilterById = []
            // Reset nodes back to unfiltered state
            if (!filterByIds) {
                setCheckedNodes([])
                // setFilteredNodes([{
                //     label: 'Wszystkie nośniki',
                //     value: 'all',
                //     children: nodes
                // }] as any);
                // return;
            }
            const reducedNodes = nodes.reduce(filterNodesByIds, [])
            // setFilteredNodes(
            //     [{
            //         label: 'Wszystkie nośniki',
            //         value: 'all',
            //         children: reducedNodes,
            //     }] as any)
            setCheckedNodes(checkedMediaOnFilterById)
            // checkboxTreeRef.current.onExpandAll()

            // setCheckedNodes([...checkedNodes, reducedNodes.map((el: any) =>{return el.value})])

        }, [filterByIds], 100
    );

    useDebounce(() => {
            const customScheduleRows = scheduleRows.find(el => el.voohItemType === VoohItemType.MediabankResource)
            if (customScheduleRows && advertisingBlocksNumber >= MIN_ADVERTISEMENTS && !rollbackAdvNumber) {
                setOpenWarningAfterChangingAdvNumber(true)
            } else {
                setAdvertisingBlocksNumberPrevValue(advertisingBlocksNumber)
                if (!rollbackAdvNumber && advertisingBlocksNumber >= MIN_ADVERTISEMENTS) {
                    const advertisements: ScheduleRow[] = [...Array(advertisingBlocksNumber).keys()].map((_, index) => {
                        return {
                            name: 'REKLAMA',
                            uuid: getUuid(index),
                            voohItemType: VoohItemType.Advertisement,
                            duration: 0,
                            categoryId: null
                        }
                    })
                    setScheduleRows(advertisements)
                }
                setRollbackAdvNumber(false)
            }
        }, [advertisingBlocksNumber], 800
    );

    async function getFilesMeta(ids: number[]) {
        let query = ids.map((id) => {
            return `mediabank_resource_ids[]=${id}`
        }).join('&');
        return getFilesMetaData(query).unwrap()

        // .then(async (res: ResourceMetaData[]) => {
        // setScheduleRows([...scheduleRows,
        //     ...res.map((resourceMetaData, index) => {
        //         return {
        //             ...resourceMetaData,
        //             name: mediabankResources?.find(el => el.id === resourceMetaData.mediabankResourceId)?.name || '',
        //             uuid: (Date.now() + Math.random() + index).toString()
        //         }
        //     })])
        // })
    };

    function getComparator<Key extends keyof any>(order: Order, orderBy: Key,): (a: any, b: any) => number {
        return order === 'desc'
            ? (a, b) => descendingComparator(a, b, orderBy)
            : (a, b) => -descendingComparator(a, b, orderBy);
    }

    function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
        if (b[orderBy] < a[orderBy]) {
            return -1;
        }
        if (b[orderBy] > a[orderBy]) {
            return 1;
        }
        return 0;
    }

    const visibleRows = React.useMemo(
        () =>
            mediabankResources ? [...mediabankResources]
                .sort(getComparator(order, orderBy)) : [],
        [order, orderBy, mediabankResources]
    );

    const getMediabankItemStyle = (isDragging: any, draggableStyle: any) => ({

        transform: 'none !important',
        // change background colour if dragging
        background: isDragging ? 'lightgreen' : null,
        // styles we need to apply on draggables
        ...draggableStyle
    });

    const getItemStyle = (isDragging: any, draggableStyle: any, voohItemType: VoohItemType, categoryName?: string) => {

        const voohRowColor = voohItemType === VoohItemType.Advertisement ? ADVERTISEMENTS_COLOR : voohCategories?.find(el => el.name === categoryName)?.color;
        return {
            // some basic styles to make the items look a bit nicer
            userSelect: "none",

            // change background colour if dragging
            background: isDragging ? (voohRowColor || '#e5e6eb') : (voohRowColor || '#ffffff'),
            display: isDragging ? 'table' : 'table-row',
            textAlign: isDragging ? 'right' : 'none',

            // styles we need to apply on draggables
            ...draggableStyle
        }
    }
    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250,
            },
        },
    };

    function getStyles() {
        return {
            fontWeight: theme.typography.fontWeightMedium
        };
    }

    const getVoohListItemCellStyle = (isDragging: boolean) => ({
        // some basic styles to make the items look a bit nicer
        // float: isDragging ? 'right' : 'none',
        width: isDragging ? '14.3%' : 0,

        // styles we need to apply on draggables
    });

    const getMediabankListItemCellStyle = (isDragging: any) => ({
        // some basic styles to make the items look a bit nicer
        // float: isDragging ? 'right' : 'none',
        borderBottom: isDragging ? 0 : '1px solid rgba(224, 224, 224, 1)',

        // styles we need to apply on draggables
    });


    const handleRequestSort = (
        event: React.MouseEvent<unknown>,
        property: keyof MediabankResourceTableRow,
    ) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.checked && mediabankResources && mediabankResources.some(el => el.resourceType === ResourceType.File)) {
            const newSelected = mediabankResources?.filter(el => el.resourceType === ResourceType.File).map((n) => n.id);
            setSelected(newSelected);
            return;
        }
        setSelected([]);
    };

    const getVoohListStyle = (isDraggingOver: boolean) => ({
        background: isDraggingOver ? 'lightblue' : 'lightgrey',
    });

    const getResourceImage = (row: MediabankResource) => {
        if (row.resourceType === ResourceType.Dir) {
            return <FolderOutlinedIcon fontSize="large"/>
        }

        if (row.resourceType === ResourceType.File && !row.thumbnailUrl) {
            return <DescriptionOutlinedIcon fontSize="large"/>
        }

        return <Box sx={{
            height: Thumbnail.width,
            width: Thumbnail.width,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            background: Thumbnail.background
        }}><img
            width={Thumbnail.width}
            src={`${row.thumbnailUrl}`}
            alt={row.name}
            loading="lazy"/>
        </Box>
    }
    const handleOpenNodesModal = () => {
        setOpenMediaTreeModal(true)
    };
    const handleCloseNodesModal = () => {
        setOpenMediaTreeModal(false)
    };

    const handleSelectTemplate = (templateId: number) => {
        setSelectedTemplate(templateId);
        const selectedTemplate = mediaTemplatesData!.find(template => template.id === templateId);
        setCheckedNodes(selectedTemplate!.media.map(el => el + '_Medium'));
    }

    const addAuthorizedPerson = (authorizedPerson: number | null, authorizedPersonRights: string) => {
        if (!authorizedPerson) {
            return;
        }

        dispatch(setVoohForm({
            ...voohForm,
            ownershipsAttributes: [...voohForm.ownershipsAttributes, {
                userId: authorizedPerson,
                accessLevel: authorizedPersonRights
            }]
        }));
    }

    const handleCheck = (event: React.MouseEvent<unknown> | null, id: number) => {
        const selectedIndex = selected.indexOf(id);
        let newSelected: number[] = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }
        setSelected(newSelected);
    };

    const mediabankDraggableItem = (provider: any, snapshot: any, row: any, isItemSelected: boolean, labelId: string, copy: boolean) => {

        const draggableProps = copy ? {} : provider.draggableProps;
        const dragHandleProps = row.resourceType === ResourceType.Dir ? {} : provider.dragHandleProps;

        return (<TableRow
            className={{
                dragging: snapshot.isDragging,
                ['dragging-multiple']: snapshot.isDragging && !copy && selected.length > 0 && isItemSelected
            }}
            key={`${copy ? row.id + '_copy' : row.id}`}
            {...draggableProps}
            hover
            onClick={(event: React.ChangeEvent<any>) => {
                if (row.resourceType === ResourceType.Dir) {
                    setParentIdForMediabankDialog(row.id.toString())
                    setBreadcrumbsForMediabankDialog([...breadcrumbsForMediabankDialog, {
                        dirName: row.name,
                        resourceId: row.id.toString()
                    }])
                    setSelected([])
                } else {
                    handleCheck(null, row.id)
                }
            }}
            onDrag={() => {
                if (row.resourceType === ResourceType.File) {
                    handleCheck(null, row.id)
                }
            }}
            role="row"
            data-content={snapshot.isDragging && !copy && selected.length > 0 ? selected.length : null}
            aria-checked={isItemSelected}
            tabIndex={-1}
            selected={isItemSelected}
            style={copy ? null : getMediabankItemStyle(
                snapshot.isDragging,
                provider.draggableProps.style,
            )}
            sx={{cursor: 'pointer'}}
            ref={copy ? null : provider.innerRef}
        >
            <TableCell size="small"
                       padding="checkbox"   {...dragHandleProps}>
                {row.resourceType === ResourceType.File ? (
                    <Checkbox
                        style={getMediabankListItemCellStyle(
                            snapshot.isDragging,
                        )}
                        color="primary"
                        onClick={(event) => handleCheck(event, row.id)}
                        checked={isItemSelected}
                        inputProps={{
                            'aria-labelledby': labelId,
                        }}
                    />) : (<SubdirectoryArrowRightIcon
                    sx={{paddingLeft: '10px'}}></SubdirectoryArrowRightIcon>)}
            </TableCell>
            <TableCell size="small" component="th" scope="row"
                       style={getMediabankListItemCellStyle(
                           snapshot.isDragging,
                       )}
                       id={labelId}
                       {...dragHandleProps}>
                <Box sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '10px'
                }}>{getResourceImage(row)}{row.name}
                </Box>
            </TableCell>
            <TableCell size="small"
                       style={getMediabankListItemCellStyle(
                           snapshot.isDragging,
                       )}
                       align="left"
                       {...dragHandleProps}
            >{row.byteSizeHumanized}</TableCell>
            <TableCell size="small"
                       style={getMediabankListItemCellStyle(
                           snapshot.isDragging,
                       )}
                       align="left"
                       {...dragHandleProps}
            >{row.metadata?.height ? (`${row.metadata?.height} x ${row.metadata?.width} px`) : ''}</TableCell>
            <TableCell size="small"
                       style={getMediabankListItemCellStyle(
                           snapshot.isDragging,
                       )}
                       align="right"
                       {...dragHandleProps}> {row.tagList.length > 0 ? row.tagList.join(', ') : ''}</TableCell>
            <TableCell size="small"
                       style={getMediabankListItemCellStyle(
                           snapshot.isDragging,
                       )}
                       align="right"
                       {...dragHandleProps}>{voohCategories?.filter(el => el.id === row.categoryId)[0]?.name} </TableCell>
            <TableCell size="small"
                       style={getMediabankListItemCellStyle(
                           snapshot.isDragging,
                       )}
                       align="right"
                       {...dragHandleProps}>{row.updatedAt ? row.updatedAt : ''}</TableCell>
        </TableRow>)
    }


    function EnhancedTableHead(props: EnhancedTableProps) {
        const {onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort} =
            props;
        const createSortHandler =
            (property: keyof MediabankResourceTableRow) => (event: React.MouseEvent<unknown>) => {
                onRequestSort(event, property);
            };

        return (
            <TableHead>
                <TableRow>
                    <TableCell size="small" padding="checkbox">
                        <Checkbox
                            color="primary"
                            indeterminate={numSelected > 0 && numSelected < rowCount}
                            checked={rowCount > 0 && numSelected === rowCount}
                            onChange={onSelectAllClick}
                        />
                    </TableCell>
                    {headCells.map((headCell) => (
                        <TableCell size="small"
                                   key={headCell.id}
                                   align={headCell.numeric ? 'right' : 'left'}
                                   padding={headCell.disablePadding ? 'none' : 'normal'}
                                   sortDirection={orderBy === headCell.id ? order : false}
                        >
                            {headCell.sort ? (<TableSortLabel
                                active={orderBy === headCell.id}
                                direction={orderBy === headCell.id ? order : 'asc'}
                                onClick={createSortHandler(headCell.id)}
                            >
                                {headCell.label}
                                {orderBy === headCell.id ? (
                                    <Box component="span" sx={visuallyHidden}>
                                        {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                    </Box>
                                ) : null}
                            </TableSortLabel>) : headCell.label}
                        </TableCell>
                    ))}
                </TableRow>
            </TableHead>
        );
    }

    const wieghtSum = Math.round(scheduleRows?.reduce((sum, current) => sum + current.byteSize!, 0)! / 10000) / 100
    const sumSec = scheduleRows?.reduce((sum, current) => sum + (current?.duration || 0), 0)!

    function secondsToTime(secondsTotal: number) {
        const m = Math.floor(secondsTotal % 3600 / 60).toString().padStart(2, '0'),
            s = Math.floor(secondsTotal % 60).toString().padStart(2, '0');

        return m + ':' + s;
    }

    function getTimePeriod(index: number) {
        let slicedSchedulePeriodLeft = scheduleRows.slice(0, (index));
        let slicedSchedulePeriodRight = scheduleRows.slice(0, (index + 1));
        const startAt = slicedSchedulePeriodLeft?.reduce((sum, current) => sum + (current?.duration || 0), 0) || 0
        const endAt = slicedSchedulePeriodRight?.reduce((sum, current) => sum + (current?.duration || 0), 0) || 0
        if (index === 0) {
            return '00:00' + ' - ' + secondsToTime(endAt)
        }
        return secondsToTime(startAt) + '  -  ' + secondsToTime(endAt)
    }

    const saveForm = async () => {
        setFormErrors({
            name: !voohForm.name.trim(),
            endAt: !voohForm.endAt,
            startAt: !voohForm.startAt,
            media: voohForm.localisablesMediatablesAttributes.length === 0,
        })

        if (!voohForm.name || !voohForm.endAt || !voohForm.endAt || voohForm.localisablesMediatablesAttributes.length === 0) {
            enqueueSnackbar('Formularz zawiera błędy', {variant: 'error'});
            return;
        }

        try {
            const result = await createVoohPlaylist(voohForm).unwrap()
            navigate('/emissions/vooh-list')
            enqueueSnackbar('Pomyślnie utworzono ramówke', {variant: 'success'});
            setVoohForm(initialVoohFormState.voohForm)
        } catch (err: any) {
            console.log(err)
            enqueueSnackbar('Wystąpił błąd', {variant: 'error'});
        }

    }
    return <>
        <Box className={'VoohForm'}>
            <Box className={'page-header'} sx={{paddingBottom: '20px'}}>
                <h1>{t('vooh.scheduleVOOH')}</h1>
                <p>Uzupenij parametry ramówki</p>
            </Box>
        </Box>
        <form autoComplete="off" onSubmit={() => {
        }}>

            <TextField
                value={voohForm.name}
                label="Nazwa ramówki"
                onChange={(event) => {
                    setFormErrors({...formErrors, name: !event.target.value.trim()})
                    dispatch(setVoohForm({
                        ...voohForm,
                        name: event.target.value
                    }));
                }}
                required
                variant="outlined"
                sx={{mb: 3}}
                fullWidth
                error={formErrors.name}/>

            <FormControl fullWidth sx={{marginTop: '15px', paddingBottom: '20px'}}>
                <InputLabel shrink id="demo-simple-select-label">Format nośnika</InputLabel>
                <Select
                    variant={"outlined"}
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label="Format nośnika"
                    value={voohForm.mediaFormat || ''}
                    notched
                    onChange={(event) => {
                        dispatch(setVoohForm({
                            ...voohForm,
                            mediaFormat: event.target.value
                        }));
                    }}
                >
                    <MenuItem
                        key={'MOVE_TV'}
                        value={'MOVE_TV'}>Vooh</MenuItem>
                </Select>
            </FormControl>

            <Button disabled={loadingMediaTree} variant="contained"
                    onClick={handleOpenNodesModal} sx={{marginTop: '20px'}}>{t('draftForm.mediaPicker')}&nbsp;
                {(loadingMediaTree) && voohForm.mediaFormat ? <CircularProgress sx={{marginLeft: '10px'}}
                                                                                size={20}/> : `${checkedNodes.length}/${mediaLength || 0}`}</Button>
            {formErrors.media && (<Typography
                sx={{marginBottom: '20px'}}
                color={'error'}
            >Ramówka musi mieć wybrane nośniki...</Typography>)}
            <Dialog
                open={openMediaTreeModal}
                onClose={handleCloseNodesModal}
                fullWidth={true}
                maxWidth="md"
            >

                <DialogTitle id="alert-dialog-title">
                    {t("draftForm.chooseMedia")}
                </DialogTitle>
                <DialogContent>

                    {(loadingMediaTree) && (
                        <Box sx={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '300px'}}>
                            <CircularProgress size={100}/>
                        </Box>)}

                    <Box sx={{minHeight: '300px'}}>
                        {mediaTree && (
                            <><TextField
                                value={filterNodesText}
                                label="Wyszukaj..."
                                onInput={onFilterNodeChange}
                                variant="outlined"
                                sx={{mt: 1, mb: 3}}
                                fullWidth
                            />
                                <TextField
                                    value={filterByIds}
                                    label="Zaznacz nośniki podając ID klubów"
                                    onInput={onSelectMediaById}
                                    helperText={"Dozwolone separatory: spacja, średnik, przecinek"}
                                    variant="outlined"
                                    sx={{mt: 1, mb: 3}}
                                    fullWidth
                                />

                                <FormControl fullWidth sx={{marginBottom: '30px'}}>
                                    <InputLabel shrink id="demo-simple-select-label">Szablony</InputLabel>
                                    <Select
                                        size="small"
                                        variant={"outlined"}
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        label="Szablony"
                                        value={selectedTemplate}
                                        renderValue={(selected) => (
                                            <span>{mediaTemplatesData && mediaTemplatesData.filter(el => el.id === selected)[0].name}</span>
                                        )}
                                        notched
                                    >
                                        {mediaTemplatesData?.length === 0 && (<MenuItem value="" onClick={() => {
                                            setSelectedTemplate('')
                                        }}>
                                            <em>Brak szablonów</em>
                                        </MenuItem>)}
                                        {mediaTemplatesData && mediaTemplatesData.map(template => (

                                            <MenuItem value={template.id} onClick={() => {
                                                handleSelectTemplate(template.id)
                                            }} key={template.id}>
                                                <Box sx={{
                                                    display: 'flex',
                                                    justifyContent: 'space-between',
                                                    alignItems: 'center',
                                                    width: '100%'
                                                }}>
                                                    <span>{template.name}</span>
                                                    <IconButton color={'error'} edge="end" aria-label="remove"
                                                                onClick={(event) => {
                                                                    event.stopPropagation();
                                                                    setOpenRemoveTemplateConfirmation(template.id)
                                                                }}>
                                                        <CloseIcon/>
                                                    </IconButton>
                                                </Box>
                                            </MenuItem>
                                        ))}

                                    </Select>
                                </FormControl>

                                {openRemoveTemplateConfirmation && (<ConfirmDialog title={'Usuwanie szablonu'}
                                                                                   description={`Czy na pewno chcesz usunąć szablon ${mediaTemplatesData?.filter(el => el.id === openRemoveTemplateConfirmation as number)[0].name}?`}
                                                                                   confirmText={'usuń'}
                                                                                   open={!!openRemoveTemplateConfirmation}
                                                                                   onConfirm={() => {
                                                                                       removeTemplate(openRemoveTemplateConfirmation as number)
                                                                                       setOpenRemoveTemplateConfirmation(null)
                                                                                   }}
                                                                                   onCancel={() => {
                                                                                       setOpenRemoveTemplateConfirmation(null)
                                                                                   }}></ConfirmDialog>)}


                                <CheckboxTree
                                    ref={checkboxTreeRef}
                                    key={treeState.key}
                                    showNodeTitles={true}
                                    nodes={filteredNodes}
                                    checked={checkedNodes}
                                    expanded={expanded}
                                    showExpandAll={true}
                                    onCheck={(checked: any) => {
                                        setCheckedNodes(checked)
                                        setTreeState({key: Math.random()})
                                        setSelectedTemplate('')
                                    }}
                                    onExpand={(expanded: any) => {
                                        setExpanded(expanded)
                                    }}
                                /></>
                        )}
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button disabled={checkedNodes.length === 0} variant={'contained'} onClick={() => {
                        setOpenSaveTemplateModal(true)
                    }}>Zapisz jako szablon</Button>
                    <Button onClick={handleCloseNodesModal}>{t('common.closeWindow')}</Button>

                </DialogActions>


            </Dialog>

            {openSaveTemplateModal && (<Dialog
                open={openSaveTemplateModal}
                onClose={(value) => {

                }}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    Zapisz nowy szablon
                </DialogTitle>
                <DialogContent>
                    <TextField
                        value={templateName}
                        label="Nazwa szablonu"
                        onChange={(event) => {
                            setTemplateName(event.target.value)
                        }}
                        required
                        InputProps={{inputProps: {min: 1}}}
                        type="text"
                        variant="outlined"
                        sx={{my: 3, width: '250px'}}></TextField>
                </DialogContent>
                <DialogActions>
                    <Button disabled={!templateName} variant={'contained'} onClick={() => {
                        createTemplate()
                    }}>{t('common.save')}</Button>
                    <Button onClick={() => {
                        setOpenSaveTemplateModal(false)
                    }} autoFocus>
                        {t('common.cancel')}
                    </Button>
                </DialogActions>
            </Dialog>)}


            <FormControl fullWidth sx={{marginTop: '45px', paddingBottom: '20px'}}>
                <InputLabel id="demo-multiple-chip-label">Tagi</InputLabel>
                <Select
                    labelId="demo-multiple-chip-label"
                    id="demo-multiple-chip"
                    multiple
                    value={voohForm.tagList}
                    onChange={(event) => {
                        dispatch(setVoohForm({
                            ...voohForm,
                            tagList: typeof event.target.value === "string" ? [event.target.value] : event.target.value
                        }));
                    }}
                    input={<OutlinedInput id="select-multiple-chip" label="Chip"/>}
                    renderValue={(selected) => (
                        <Box sx={{display: 'flex', flexWrap: 'wrap', gap: 0.5}}>
                            {selected.map((value) => (
                                <Chip key={value} label={value}/>
                            ))}
                        </Box>
                    )}
                    MenuProps={MenuProps}
                >
                    {tagsData && (tagsData.map((tag) => (
                        <MenuItem
                            key={tag.name}
                            value={tag.name}
                            style={getStyles()}
                        >
                            {tag.name}
                        </MenuItem>
                    )))}
                </Select>
            </FormControl>
            <Divider sx={{paddingY: '20px'}} textAlign="left"><Typography
                sx={{color: 'text.secondary'}}>Uzupełnij schemat ramówki</Typography></Divider>
            <TextField
                value={advertisingBlocksNumber}
                label="Ilość bloków reklamowych w ramówce"
                onChange={(event) => {
                    setAdvertisingBlocksNumber(parseInt(event.target.value))
                }}
                required
                InputProps={{inputProps: {min: 4}}}
                type="number"
                variant="outlined"
                sx={{mb: 3, width: '250px'}}
                helperText={(advertisingBlocksNumber >= MIN_ADVERTISEMENTS) ? '' : `Ilość bloków musi być większa niż ${MIN_ADVERTISEMENTS}`}
                error={advertisingBlocksNumber < MIN_ADVERTISEMENTS}/>
            <Box sx={{display: 'flex', justifyContent: 'space-between', gap: '20px'}}>
                <DragDropContext onDragEnd={handleDragEnd}>
                    <TableContainer component={Paper} sx={{height: VOOH_SCHEDULE_HEIGHT}}>
                        <Table stickyHeader className={'VoohForm__droppable-table'}
                               sx={{minWidth: 650, height: scheduleRows.length === 0 ? '100%' : 'auto'}}
                               aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell width={'1%'} size="small"></TableCell>
                                    <TableCell width={'40%'} size="small">Nazwa</TableCell>
                                    <TableCell size="small" align="right">Suma <b>{wieghtSum || ''} MB</b></TableCell>
                                    <TableCell size="small" align="right">Przedział czasowy</TableCell>
                                    <TableCell size="small"
                                               align="right">Suma <b>{sumSec ? (secondsToTime(sumSec)) : ''} min</b></TableCell>
                                    <TableCell size="small" align="right">Kategoria</TableCell>
                                    <TableCell size="small" align="right">Akcje</TableCell>
                                </TableRow>
                            </TableHead>
                            <Droppable droppableId="droppable-1">
                                {(provider: any, snapshot: any) => (
                                    <TableBody className={'vooh-table-body'}
                                               ref={provider.innerRef} {...provider.droppableProps}
                                               style={getVoohListStyle(snapshot.isDraggingOver)}>

                                        {scheduleRows.length === 0 && <TableRow>
                                            <TableCell size="small" colSpan={7}></TableCell>

                                        </TableRow>}

                                        {scheduleRows.map((row, index) => (
                                            <Draggable key={row.uuid} draggableId={row.uuid} index={index}>
                                                {(provider, snapshot) => (
                                                    <TableRow
                                                        key={row.uuid}
                                                        sx={{"&:last-child td, &:last-child th": {border: 0}}}
                                                        {...provider.draggableProps}
                                                        style={getItemStyle(
                                                            snapshot.isDragging,
                                                            provider.draggableProps.style,
                                                            row.voohItemType,
                                                            row.categoryName,
                                                        )}
                                                        // style={snapshot.isDragging ? {background: 'red'} : {}}
                                                        ref={provider.innerRef}
                                                    >
                                                        <TableCell width={'1%'} size="small"
                                                                   component="th"
                                                                   scope="row"
                                                                   {...provider.dragHandleProps}
                                                        >
                                                            <DragIndicatorIcon/>
                                                        </TableCell>
                                                        <TableCell width={'85%'} size="small"
                                                                   style={getVoohListItemCellStyle(
                                                                       snapshot.isDragging
                                                                   )}>{row.name}</TableCell>
                                                        <TableCell width={'10%'} size="small"
                                                                   style={getVoohListItemCellStyle(
                                                                       snapshot.isDragging
                                                                   )} align="right">{row.byteSizeHumanized}</TableCell>
                                                        <TableCell width={'10%'} size="small"
                                                                   style={getVoohListItemCellStyle(
                                                                       snapshot.isDragging
                                                                   )}
                                                                   align="right"> {row.voohItemType === VoohItemType.MediabankResource && getTimePeriod(index)} </TableCell>
                                                        <TableCell width={'10%'} size="small"
                                                                   style={getVoohListItemCellStyle(
                                                                       snapshot.isDragging
                                                                   )}
                                                                   align="right">{row.voohItemType === VoohItemType.MediabankResource && (row.editableDuration ? (
                                                            <TextField
                                                                size={'small'}
                                                                value={row.duration}
                                                                type={"number"}
                                                                sx={{width: '70px'}}
                                                                label="sek"
                                                                onInput={(event: any) => {
                                                                    if (event.target.value < 0) {
                                                                        return
                                                                    }

                                                                    setScheduleRows(scheduleRows.map((el) => {
                                                                        if (el.uuid === row.uuid) {
                                                                            return {
                                                                                ...el,
                                                                                duration: parseInt(event.target.value)
                                                                            }
                                                                        }
                                                                        return el
                                                                    }))
                                                                }}
                                                                variant="filled"
                                                            />) : row.duration)}</TableCell>
                                                        <TableCell width={'10%'} size="small"
                                                                   style={getVoohListItemCellStyle(
                                                                       snapshot.isDragging
                                                                   )} align="right">

                                                            {row.voohItemType === VoohItemType.MediabankResource && (
                                                                <FormControl sx={{width: '120px'}}>
                                                                    <InputLabel
                                                                        id="demo-simple-select-label">{t('vooh.form.category')}</InputLabel>
                                                                    <Select
                                                                        size="small"
                                                                        labelId="demo-simple-select-label"
                                                                        id="demo-simple-select"
                                                                        value={row.categoryId?.toString() || ''}
                                                                        label={t('vooh.form.category')}
                                                                        onChange={(event: SelectChangeEvent) => {
                                                                            setScheduleRows(scheduleRows.map((el => {
                                                                                if (el.uuid === row.uuid) {
                                                                                    el.categoryId = event.target.value ? parseInt(event.target.value) : null
                                                                                }
                                                                                return el
                                                                            })))
                                                                        }}
                                                                    >
                                                                        <MenuItem
                                                                            value={''}><em>{t('common.noValue')}</em></MenuItem>
                                                                        {
                                                                            voohCategories?.map((category) => {
                                                                                return (<MenuItem
                                                                                    value={category.id}>{category.name}</MenuItem>)
                                                                            })
                                                                        }
                                                                    </Select>
                                                                </FormControl>)}

                                                        </TableCell>
                                                        <TableCell width={'10%'} size="small"
                                                                   style={getVoohListItemCellStyle(
                                                                       snapshot.isDragging
                                                                   )} align="right"><Button
                                                            color={row.voohItemType === VoohItemType.Advertisement ? 'inherit' : 'error'}
                                                            variant="text"
                                                            onClick={() => {
                                                                if (row.voohItemType === VoohItemType.Advertisement && advertisingBlocksNumber === MIN_ADVERTISEMENTS) {
                                                                    return
                                                                }
                                                                const newScheduleRows = scheduleRows.filter((el) => el.uuid !== row.uuid)
                                                                setScheduleRows(newScheduleRows)
                                                                if (row.voohItemType === VoohItemType.Advertisement) {
                                                                    setRollbackAdvNumber(true)
                                                                    setAdvertisingBlocksNumber(newScheduleRows.filter((el) => el.voohItemType === VoohItemType.Advertisement).length)
                                                                }
                                                            }}
                                                        ><strong>{t('common.remove')}</strong></Button></TableCell>
                                                    </TableRow>
                                                )}
                                            </Draggable>
                                        ))}
                                        {provider.placeholder}
                                    </TableBody>
                                )}
                            </Droppable>
                        </Table>
                    </TableContainer>
                    <Paper sx={showFileExplorer ? {} : {display: 'flex', alignItems: 'center'}}>
                        <Box sx={{display: 'flex', flexDirection: 'row'}}>
                            <Box sx={{display: 'flex', alignItems: 'center'}}>
                                <IconButton sx={{marginX: '4px'}} onClick={() => {
                                    setShowFileExplorer(!showFileExplorer)
                                }} aria-label="expand">
                                    {showFileExplorer ? (<ArrowForwardIosIcon/>) : (<ArrowBackIosIcon/>)}
                                </IconButton>
                            </Box>
                            <Box sx={{display: showFileExplorer ? 'block' : 'none'}}>
                                <Breadcrumbs aria-label="breadcrumb" sx={{padding: '10px'}}>
                                    {breadcrumbsForMediabankDialog.length && breadcrumbsForMediabankDialog.map((breadcrumb, index) => {
                                        return index !== breadcrumbsForMediabankDialog.length - 1 ? (
                                            <Link key={breadcrumb.dirName} underline="hover"
                                                  color="inherit"
                                                  onClick={() => {
                                                      setParentIdForMediabankDialog(breadcrumb.resourceId)
                                                      setBreadcrumbsForMediabankDialog(breadcrumbsForMediabankDialog.slice(0, index + 1))
                                                      setSelected([])
                                                  }} component="button">
                                                {breadcrumb.dirName}
                                            </Link>
                                        ) : (<Typography key={breadcrumb.dirName}
                                                         sx={{color: 'text.primary'}}>{breadcrumb.dirName}</Typography>)
                                    })}
                                </Breadcrumbs>
                                {
                                    (mediabankResources) && (
                                        <>
                                            <TableContainer
                                                sx={{width: '900px', height: `calc(${VOOH_SCHEDULE_HEIGHT} - 102px)`}}>
                                                {isFetching ? (<Box sx={{
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    width: '100%',
                                                    alignItems: 'center'
                                                }}>
                                                    <CircularProgress size="30px"/>
                                                </Box>) : (<Table stickyHeader={true} aria-label="simple table">
                                                    <EnhancedTableHead
                                                        numSelected={selected.length}
                                                        order={order}
                                                        orderBy={orderBy}
                                                        onSelectAllClick={handleSelectAllClick}
                                                        onRequestSort={handleRequestSort}
                                                        rowCount={mediabankResources.filter(el => el.resourceType === ResourceType.File).map((n) => n.id).length}
                                                    />

                                                    <Droppable droppableId="droppable-2">
                                                        {(provider: any) => (
                                                            <TableBody ref={provider.innerRef} {...provider.droppableProps}
                                                                       className={'mediabank-draggable-list'}>
                                                                {visibleRows.map((row, index) => {
                                                                    const isItemSelected = selected.includes(row.id);
                                                                    const labelId = `enhanced-table-checkbox-${index}`;
                                                                    return (
                                                                        <Draggable key={row.id.toString()}
                                                                                   draggableId={row.id.toString()}
                                                                                   index={index}>
                                                                            {(provider, snapshot) => (
                                                                                <>
                                                                                    {
                                                                                        mediabankDraggableItem(provider, snapshot, row, isItemSelected, labelId, false)
                                                                                    }
                                                                                    {
                                                                                        snapshot.isDragging ? mediabankDraggableItem(provider, snapshot, row, isItemSelected, labelId, true) : null
                                                                                    }

                                                                                </>

                                                                            )}
                                                                        </Draggable>
                                                                    )
                                                                })}
                                                                {provider.placeholder}
                                                            </TableBody>
                                                        )}
                                                    </Droppable>
                                                </Table>)}
                                            </TableContainer>
                                            <Droppable droppableId="droppable-3">
                                                {(provider: any) => (
                                                    <div ref={provider.innerRef} {...provider.droppableProps}>
                                                        <Draggable key={'advertisement'}
                                                                   draggableId={'advertisement'}
                                                                   index={0}
                                                        >
                                                            {(provider, snapshot) => (

                                                                <>
                                                                    <ListItemButton
                                                                        component="a" sx={{
                                                                        backgroundColor: ADVERTISEMENTS_COLOR,
                                                                        display: snapshot.isDragging ? 'block' : 'none',
                                                                        marginTop: '10px'
                                                                    }}>
                                                                        <ListItemText
                                                                            primary="REKLAMA"/>

                                                                    </ListItemButton>
                                                                    <ListItemButton
                                                                        ref={provider.innerRef}  {...provider.dragHandleProps} {...provider.draggableProps}
                                                                        component="a" sx={{
                                                                        backgroundColor: ADVERTISEMENTS_COLOR,
                                                                        marginTop: '10px'
                                                                    }}>
                                                                        <ListItemText
                                                                            primary="REKLAMA"/>

                                                                    </ListItemButton>
                                                                </>
                                                            )}
                                                        </Draggable>
                                                    </div>
                                                )}
                                            </Droppable>

                                        </>
                                    )
                                }</Box>
                        </Box>

                    </Paper>
                </DragDropContext>
            </Box>
            {openWarningAfterChangingAdvNumber && (<Dialog
                open={openWarningAfterChangingAdvNumber}
                onClose={(value) => {

                }}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    Ostrzeżenie
                </DialogTitle>
                <DialogContent>
                    <Typography
                        sx={{color: 'text.primary', paddingY: '10px'}}>Zmiana ilość bloków reklamowych usunie dodane
                        ramówki, czy na pewno chesz kontynuować?</Typography>
                </DialogContent>
                <DialogActions>
                    <Button variant={'contained'} onClick={() => {
                        setOpenWarningAfterChangingAdvNumber(false);
                        setAdvertisingBlocksNumberPrevValue(advertisingBlocksNumber)
                        const advertisements: ScheduleRow[] = [...Array(advertisingBlocksNumber).keys()].map((_, index) => {
                            return {
                                name: 'REKLAMA',
                                uuid: getUuid(index),
                                voohItemType: VoohItemType.Advertisement,
                                duration: 0,
                                categoryId: null
                            }
                        })
                        setScheduleRows(advertisements)
                    }}>Tak, zmień ilość</Button>
                    <Button onClick={() => {
                        setOpenWarningAfterChangingAdvNumber(false);
                        setAdvertisingBlocksNumber(advertisingBlocksNumberPrevValue)
                        setRollbackAdvNumber(true)
                    }} autoFocus>
                        {t('common.cancel')}
                    </Button>
                </DialogActions>
            </Dialog>)}

            <Divider sx={{paddingY: '10px'}}></Divider>


            <Box sx={{display: 'flex', gap: '40px', alignItems: 'center', paddingTop: '40px'}}>
                <Box sx={{width: '100%'}}>
                    <DateTimePicker
                        minDate={dayjs(new Date())}
                        maxDate={voohForm.endAt ? dayjs(new Date(voohForm.endAt)) : undefined}
                        value={dayjs(voohForm.startAt)}
                        slotProps={{
                            textField: {
                                fullWidth: true,
                                variant: 'outlined',
                                error: formErrors.startAt
                            },
                        }}
                        label={t('creations.steps.fillInCreationParameters.emissionStart')}
                        onChange={(event) => {
                            setFormErrors({...formErrors, startAt: false})
                            dispatch(setVoohForm({
                                ...voohForm,
                                startAt: dayjs(event).date() ? dayjs(event).format() : ''
                            }))
                        }}/></Box>
                <span>-</span>
                <Box sx={{width: '100%'}}>
                    <DateTimePicker
                        minDate={voohForm.startAt ? dayjs(voohForm.startAt) : dayjs(new Date())}
                        value={dayjs(voohForm.endAt)}
                        slotProps={{
                            textField: {
                                fullWidth: true,
                                variant: 'outlined',
                                error: formErrors.endAt
                            },
                        }}
                        label={t('creations.steps.fillInCreationParameters.emissionEnd')}
                        onChange={(event) => {
                            setFormErrors({...formErrors, endAt: false})
                            dispatch(setVoohForm({
                                ...voohForm,
                                endAt: dayjs(event).date() ? dayjs(event).format() : ''
                            }))
                        }}/></Box>
            </Box>

            <OwnershipSelect ownershipsAttributes={voohForm.ownershipsAttributes}
                             onDelete={(val) => {
                                 const removingFromDb = undefined
                                 dispatch(setVoohForm({
                                     ...voohForm,
                                     ownershipsAttributes: voohForm.ownershipsAttributes.filter(person => person.userId !== parseInt(val))
                                 }));
                             }}

                             onAdd={addAuthorizedPerson}
                             onDeleteAll={() => {
                                 dispatch(setVoohForm({
                                     ...voohForm,
                                     ownershipsAttributes: []
                                 }));
                             }}
            ></OwnershipSelect>


            <Box sx={{display: 'flex', justifyContent: 'end', padding: '20px'}}>
                <Button variant={'outlined'} onClick={saveForm}>{t('common.save')}</Button>
            </Box>

        </form>


    </>
}

export default VoohForm;




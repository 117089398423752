import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {MediabankFile, MediabankResourcesFilters} from '../types';
import {RootState} from '../../../store';
import {creationsSlice} from "../../creations/store/creationsSlice";

export interface MediabankResourcesInitialState {
    filters: MediabankResourcesFilters;
    resourceBreadcrumbs: BreadCrumb[];
    showUploudBox: boolean;
}

export interface BreadCrumb {
    resourceId: string | null;
    dirName: string;
}

export const initialCreationFormState: MediabankResourcesInitialState = {
    filters: {
        nameCont: '',
        tagsNameIn: [],
        updatedAtEq: '',
        parentIdEq: null
    },
    showUploudBox: false,
    resourceBreadcrumbs: [{resourceId: null, dirName: 'Folder główny'}],
}


export const mediabankResourcesSlice = createSlice({
    name: "creations",
    initialState: initialCreationFormState,
    reducers: {
        setMediabankFilters: (state, action: PayloadAction<MediabankResourcesFilters>) => {
            state.filters = action.payload
        },
        setVisibleUploudBox: (state, action: PayloadAction<boolean>) => {
            state.showUploudBox = action.payload
        },
        setBreadCrumbs: (state, action: PayloadAction<BreadCrumb[]>) => {
            state.resourceBreadcrumbs = action.payload
        }
    }
})
export const { setMediabankFilters, setBreadCrumbs, setVisibleUploudBox} = mediabankResourcesSlice.actions;

export const selectCurrentMediabankResourcesFiltersState = (state: RootState) => state.mediabank.filters;
export const selectBreadCrumbs = (state: RootState) => state.mediabank.resourceBreadcrumbs;
export const selectUploudBoxVisibility = (state: RootState) => state.mediabank.showUploudBox;

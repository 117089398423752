import {MediabankResource} from "../mediabank/types";
import {MediatableAttribute} from "../drafts/types";
import {OwnershipAttribute} from "../creations/types";

export interface GetResourcesMetaDataResponse {
    id: string;
    name: string;
    voohItems: ResourceMetaData[]
}


export type ResourceMetaData = Pick<MediabankResource, 'id' | 'byteSize' | 'byteSizeHumanized'> & {categoryColor: string, categoryName: string, categoryId:number | null, duration: number, itemType: VoohItemType, mediabankResourceId: number, position: number, editableDuration: boolean, voohItemType: VoohItemType};

export interface VoohCategory {
    id: number
    name: string;
    color: string;
}

export enum VoohItemType {
    Advertisement = "advertisement",
    MediabankResource = "mediabankResource",
}

export interface VoohForm {
    name: string;
    mediaFormat: string | undefined;
    tagList: string[];
    startAt: string;
    endAt: string;
    voohItemsAttributes: VoohItem[];
    localisablesMediatablesAttributes: MediatableAttribute[];
    ownershipsAttributes: OwnershipAttribute[];
}

export type VoohListItem = Pick<VoohForm, 'name' | 'mediaFormat' |'tagList' | 'startAt' | 'endAt' > & { id: number, voohPlaylistStatus: string };



export interface VoohItem {
    mediabankResourceId?: number;
    position: number;
    duration: number;
    itemType: VoohItemType;
    categoryId: number | null;
}

export type ScheduleRow = Partial<ResourceMetaData> & {name?: string, uuid: string, voohItemType: VoohItemType, duration: number, categoryId: number| null};


interface MediaTemplateBase {
    id: number,
    name: string,
}

export interface MediaTemplate extends MediaTemplateBase {
    media: number[]
}

export interface MediaTemplateResponse extends MediaTemplateBase {
    media: {id: number}[]
}

export interface MediaTemplatePayload {
    name: string;
    localisablesMediatablesAttributes: MediatableAttribute[];
}

export interface VoohListsFilteringMeta {
    filtering: {
        fields: {
            mediaFormatIn: {
                allowedValues: string[]
            }
            nameCont: {
                allowedValues: string
            }
            tagsNameIn: {
                allowedValues: string[]
            }
            voohPlaylistStatusEq: {
                allowedValues: string[]
            }
        }
    }
    order: {
        by: string[]
        dir: string[]
    }
}

export interface VoohPlayListsFilters {
    page: number;
    nameCont: string;
    mediaFormatIn: string[];
    tagsNameIn: string[];
    voohPlaylistStatusEq: string[] | string;
    startAtLteq: string;
    endAtQteq: string;
}

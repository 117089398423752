import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {OwnershipSelectProps} from "./types";
import {
    Autocomplete,
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Popper,
    TextField
} from "@mui/material";
import Chip from "@mui/material/Chip";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import {useGetResponsiblePersonsQuery} from "../../creations/api/creationsApiSlice";

const OwnershipSelect: React.FC<OwnershipSelectProps> = ({
                                                             ownershipsAttributes,
                                                             onDelete,
                                                             onDeleteAll,
                                                             onAdd,
                                                             disabled,
                                                         }) => {
    const {t} = useTranslation();
    const {isLoading: loadingResponsiblePersons, data: responsiblePersons} =
        useGetResponsiblePersonsQuery();
    const [openAddAuthorizedPersonModal, setOpenAddAuthorizedPersonModal] = useState(false);
    const [authorizedPerson, setAuthorizedPerson] = useState<number | null>(null);
    const [authorizedPersonRights, setAuthorizedPersonRights] = useState('write')

    return (<Box sx={{display: 'flex', alignItems: 'baseline'}}>
            <Autocomplete
                disabled={!!disabled}
                PopperComponent={(props) => <Popper {...props} style={{display: 'none'}}/>}
                className={'AuthorizedPersons_chip-list'}
                sx={{width: '100%', marginTop: '20px'}}
                multiple
                id="tags-outlined"
                options={[]}
                value={ownershipsAttributes.filter(el => !el._destroy).map(el => el.userId.toString())}
                isOptionEqualToValue={() => true}
                getOptionLabel={(option) => option}
                filterSelectedOptions
                renderTags={(tagValue) => {
                    return tagValue.map(val => <Chip
                        disabled={disabled}
                        label={responsiblePersons!.filter(el => el.id === parseInt(val))[0].email + ' | ' + t(`creations.steps.fillInCreationParameters.accessLevel.${ownershipsAttributes.filter(el => el.userId.toString() === val)[0].accessLevel}`)}
                        onDelete={(tag) => {
                            onDelete(val)
                        }}
                    />)
                }}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        onClick={(e) => {
                            if (disabled) {
                                return
                            }
                            if (e.target instanceof SVGElement || e.target instanceof HTMLElement) {
                                const classList = Array.from(e.target.classList)
                                if (classList.length === 0 || classList.some(element => element.includes('fontSizeSmall') || classList.some(element => element.includes('clearIndicator')))) {
                                    onDeleteAll()
                                    return
                                }
                            }
                            setOpenAddAuthorizedPersonModal(true)
                        }
                        }
                        label="Dodaj uprawnienia"
                        placeholder="Uprawniona osoba"
                    />
                )}
            />
            <Dialog
                open={openAddAuthorizedPersonModal}
                onClose={() => {
                    setOpenAddAuthorizedPersonModal(false)
                }}
                fullWidth={true}
                maxWidth="md"
            >

                <DialogTitle id="alert-dialog-title">
                    Dodaj osobe uprawnioną
                </DialogTitle>
                <DialogContent>
                    <Box sx={{display: 'flex', alignItems: 'center'}}>
                        {responsiblePersons && (<Autocomplete
                            getOptionLabel={(value) => value.email}
                            onChange={(_, value) => setAuthorizedPerson(value?.id || null)}
                            options={responsiblePersons}
                            sx={{width: 300, marginTop: '10px'}}
                            renderInput={(params) => <TextField {...params} label="Osoba uprawniona"/>}
                        />)}
                        <FormControl sx={{marginLeft: '10px', marginTop: '10px'}}>
                            <InputLabel id="demo-simple-select-label">Uprawnienia</InputLabel>
                            <Select
                                sx={{width: '150px'}}
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={authorizedPersonRights}
                                label="Uprawnienia"
                                onChange={(e) => {
                                    setAuthorizedPersonRights(e.target.value as string)
                                }}
                            >
                                <MenuItem value={'write'}>Edycja</MenuItem>
                                <MenuItem value={'read'}>Wyświetlanie</MenuItem>
                            </Select>
                        </FormControl>

                    </Box>

                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => {
                            setOpenAddAuthorizedPersonModal(false)
                        }}>{t('common.closeWindow')}</Button>
                    <Button
                        disabled={!authorizedPerson}
                        variant={'contained'} onClick={() => {
                        if (!authorizedPerson) {
                            return
                        }

                        setOpenAddAuthorizedPersonModal(false)
                        onAdd(authorizedPerson, authorizedPersonRights)
                        setAuthorizedPerson(null)

                    }}>Dodaj</Button>
                </DialogActions>
            </Dialog>
        </Box>

    )
}

export default OwnershipSelect;
import React from "react";
import {ConfirmDialogProps} from "./types";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography} from "@mui/material";
import {useTranslation} from "react-i18next";

const ConfirmDialog: React.FC<ConfirmDialogProps> = ({
    title,
    description,
    open,
    onCancel,
    onConfirm,
    confirmText,
}) => {
    const {t} = useTranslation();


    return (
        <Dialog
            open={open}
            onClose={onCancel}
            fullWidth={true}
            maxWidth="md"
        >

            <DialogTitle id="alert-dialog-title">
                {title}
            </DialogTitle>
            <DialogContent>
                <Typography
                    sx={{color: 'text.primary', paddingY: '10px'}}>
                    {description}

                </Typography>
            </DialogContent>
            <DialogActions>
                <Button  variant={'contained'} onClick={() => {
                    onConfirm();
                }}>{confirmText}</Button>
                <Button onClick={() => {
                    onCancel();
                }}>{t('common.closeWindow')}</Button>

            </DialogActions>


        </Dialog>

    )
}


export default ConfirmDialog;